var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "label inline-block leading-3 whitespace-nowrap",
    class: _vm.labelClasses[_vm.status]
  }, [_vm._v(_vm._s(_vm.statusText))]);
}
var staticRenderFns = []

export { render, staticRenderFns }
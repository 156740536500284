<template>
  <div class="label inline-block leading-3 whitespace-nowrap" :class="labelClasses[status]">{{ statusText }}</div>
</template>
<script>
export default {
  props: {
    status: {
      type: String,
      default: 'success'
    },
    statusText: {
      type: String,
      default: 'success'
    }
  },
  data() {
    return {
      labelClasses: {
        success: 'bg-green-10 py-1 px-2 rounded text-green text-xs font-bold',
        error: 'bg-red-10 py-1 px-2 rounded text-red text-xs font-bold',
        warning: 'bg-yellow-secondary py-1 px-2 rounded text-yellow text-xs font-bold'
      }
    }
  }
}
</script>
